
























import { Component, Prop, Vue } from 'vue-property-decorator';
import { PaginateAudits_paginateAudits_data_branch_endedAudits } from '@/types/intrador';

@Component({})
export default class EndedAudit extends Vue {
  @Prop(Object) protected endedAudit!: PaginateAudits_paginateAudits_data_branch_endedAudits;
  @Prop(String) protected returnString: string | null = '';
}
