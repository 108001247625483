










































import { Component, Prop, Vue } from 'vue-property-decorator';
import { CreateDealerVariables } from '@/types/intrador';
import Modal from '@/layouts/back-office/elements/modals/Modal.vue';
import SpinnerButton from '@/layouts/back-office/elements/SpinnerButton.vue';
import InputText from '@/layouts/back-office/elements/input/InputText.vue';

@Component({ components: { InputText, SpinnerButton, Modal } })
export default class CreateDealerAction extends Vue {
  @Prop(Array) protected refetchQueries?: any[];

  private name: string | null = null;
  private externalId: string | null = null;

  private loading = false;
  private error: string | null = null;

  private get variables(): CreateDealerVariables {
    return {
      dealer: {
        name: this.name!,
        externalId: this.externalId!,
      },
    };
  }

  private get savable() {
    // Minimum of two characters for the name of the dealer and the external ID of the dealer
    return this.name && this.name.length >= 2 && this.externalId && this.externalId.length >= 2;
  }

  private get modal() {
    return this.$refs.createDealerModal as Modal;
  }

  private async mutate() {
    this.loading = true;

    try {
      const { data } = await this.$apollo.mutate({
        mutation: require('@/graphql/mutations/CreateDealer.gql'),
        variables: this.variables,
        refetchQueries: this.refetchQueries,
        awaitRefetchQueries: true,
      });

      // Navigate to the detail page of the dealer and open the create branch modal.
      this.$router.push({
        name: 'dealers-detail',
        params: { id: data.createDealer.id },
        query: { createBranch: '1' },
      });
    } catch (e) {
      if (e.graphQLErrors) {
        this.error = e.graphQLErrors.map((e: any) => e.message).join(', ');
      } else {
        this.error = e.message;
      }

      this.loading = false;
    }
  }

  private open() {
    this.name = null;
    this.externalId = null;

    this.modal.open();
  }

  private close() {
    this.modal.close();
  }
}
