var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page page-locations"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('query-data-table',{ref:"queryDataTable",attrs:{"data-key":"paginateBranches","title":_vm.$it('locations.list.title', 'Locations'),"query":require('@/graphql/Audits.gql'),"variables":{limit: 50},"columns":_vm.columns,"filters":_vm.filters,"searchableFields":_vm.searchable,"row-height":63,"default-sort":"nextAudit"},scopedSlots:_vm._u([{key:"action-bar-buttons",fn:function(){return [_c('create-dealer-action',{on:{"created":_vm.refetch}})]},proxy:true},{key:"name",fn:function(ref){
var value = ref.value;
var column = ref.column;
var item = ref.item;
return [_c('router-link',{staticClass:"row-title",attrs:{"to":{ name: 'branches-detail', params: { id: item.dealer.id, branchId: item.id },
                        query: { return: 'locations'}}}},[_vm._v(" "+_vm._s(value)+" ")]),_c('meta-list',{staticClass:"row-subtitle",attrs:{"balloon":"right","meta":item.meta,"prepend":[{value: item.externalId, tooltip: _vm.$it('global.location-id', 'Location Id')}],"keys":[item.externalId || null, 'cnpj'],"tooltips":[_vm.$it('global.location-id', 'Location Id'), _vm.$it('global.cnpj', 'CNPJ')]}})]}},{key:"dealer",fn:function(ref){
                        var value = ref.value;
                        var column = ref.column;
                        var item = ref.item;
return [_c('router-link',{staticClass:"row-title",attrs:{"to":{ name: 'dealers-detail', params: { id: value.id }, query: { return: 'locations'}}}},[_vm._v(" "+_vm._s(value.name)+" ")]),(value.externalId)?_c('div',{staticClass:"row-subtitle"},[_c('meta-list',{attrs:{"balloon":"right","meta":value.meta,"prepend":[{value: value.externalId, tooltip: _vm.$it('global.dealer-id', 'Dealer Id')}],"keys":[value.externalId || null, 'cnpj'],"tooltips":[_vm.$it('global.dealer-id', 'Dealer Id'), _vm.$it('global.cnpj', 'CNPJ')]}})],1):_vm._e()]}},{key:"auditFrequency",fn:function(ref){
                        var value = ref.value;
                        var column = ref.column;
                        var item = ref.item;
return [(value)?[_vm._v(_vm._s(value)+" "+_vm._s(_vm.$it('global.days', 'days')))]:void 0]}},{key:"nextAudit",fn:function(ref){
                        var value = ref.value;
                        var column = ref.column;
                        var item = ref.item;
return [(value)?[_c('div',[_vm._v(_vm._s(_vm.$id(value, 'date')))]),_vm._v(" "+_vm._s(_vm.$it('global.every', 'Every'))+" "+_vm._s(item.auditFrequency)+" "+_vm._s(_vm.$it('global.days', 'days'))+" ")]:_vm._e()]}},{key:"nextTrustedAudit",fn:function(ref){
                        var value = ref.value;
                        var column = ref.column;
                        var item = ref.item;
return [(value)?[_c('div',[_vm._v(_vm._s(_vm.$id(value, 'date')))]),_vm._v(" "+_vm._s(_vm.$it('global.every', 'Every'))+" "+_vm._s(item.trustedAuditFrequency)+" "+_vm._s(_vm.$it('global.days', 'days'))+" ")]:_vm._e()]}},{key:"endedAudits",fn:function(ref){
                        var value = ref.value;
                        var column = ref.column;
                        var item = ref.item;
return [(value.length >= 1)?_c('div',{staticClass:"ended-audit"},[_c('ended-audit',{attrs:{"return-string":'locations',"ended-audit":value[0]}})],1):_c('div')]}},{key:"auditsClosedThisYear",fn:function(ref){
                        var value = ref.value;
                        var column = ref.column;
                        var item = ref.item;
return [_vm._v(" "+_vm._s(value)+" ")]}},{key:"auditsClosedWithAnomaliesThisYear",fn:function(ref){
                        var value = ref.value;
                        var column = ref.column;
                        var item = ref.item;
return [_vm._v(" "+_vm._s(value)+" ")]}}])})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }