





















































































import {DataTableColumn, DataTableSearchable} from '@/layouts/back-office/elements/datatable/DataTableMixin';
import {Component, Mixins, Vue} from 'vue-property-decorator';
import QueryDataTable from '@/layouts/back-office/elements/datatable/QueryDataTable.vue';
import AccountManagerFilterMixin from '@/filters/dataTable/AccountManagerFilterMixin';
import { Filter } from '@/layouts/back-office/elements/filters/FilterMixin';
import MetaList from '@/components/MetaList.vue';
import EndedAudit from '@/components/audits/EndedAudit.vue';
import {AuditRating, PaginateBranches_paginateBranches} from '@/types/intrador';
import CreateDealerAction from '@/components/dealer/actions/CreateDealerAction.vue';
import {Meta} from '@sophosoft/vue-meta-decorator';

type AuditRatingString = keyof typeof AuditRating;

const starString = `<i class="fas fa-star intrador-star"></i>`;
const starStringWarning = `<i class="fas fa-star intrador-star warning"></i>`;
const starStringDanger = `<i class="fas fa-star intrador-star danger"></i>`;
const starStringDisabled = `<i class="fas fa-star intrador-star disabled"></i>`;

@Component({
  components: {
    QueryDataTable,
    MetaList,
    EndedAudit,
    CreateDealerAction,
  },
})

export default class Locations extends Mixins(AccountManagerFilterMixin) {
  public static starRating(rating: AuditRatingString) {
    switch (AuditRating[rating]) {
      case AuditRating.CONTENT:
        return starString + starString + starString;
      case AuditRating.HALF_CONTENT:
        return starStringWarning + starStringWarning + starStringDisabled;
      case AuditRating.NOT_CONTENT:
        return starStringDanger + starStringDisabled + starStringDisabled;
      default:
        return Vue.prototype.$it('global.na', 'N/A');
    }
  }

  /**
   * The DataTable searchable
   */
  get searchable(): DataTableSearchable[] {
    const searchable: DataTableSearchable[] = [
      {
        key: 'id',
        name: '#',
      },
      {
        key: 'name',
        name: this.$it('audit.col.branch-name.title', 'Branch') as string,
      },
      {
        key: 'dealer.name',
        name: this.$it('audit.col.dealer-name.title', 'Dealer') as string,
      },
      {
        key: 'dealer.externalId',
        name: this.$it('audit.col.dealer-id.title', 'Dealer id') as string,
      },
      {
        key: 'externalId',
        name: this.$it('audit.col.location-id.title', 'Location id') as string,
      },
    ];
    const dealerId = this.$store.getters.user.entity.config.dealerUniqueIdentifier;
    if (dealerId) {
      searchable.push({
        key: 'meta.' + dealerId,
      });
    }

    return searchable;
  }

  /**
   * The DataTable columns
   */
  get columns(): DataTableColumn[] {
    return [
      {
        title: this.$it('locations.col.locations.title', 'Locations') as string,
        key: 'name',
        class: 'data-table-col-min',
        sortable: true,
        width: 200,
      },
      {
        title: this.$it('dealers.col.name.title', 'Dealer') as string,
        key: 'dealer',
        class: 'data-table-col-min',
        sortable: 'dealer.name',
        width: 200,
      },
      {
        title: this.$it('branch.col.next_audit.title', 'Next audit') as string,
        key: 'nextAudit',
        class: 'data-table-col-fixed data-table-col-middle',
        sortable: true,
        width: 150,
      },
      {
        title: this.$it('branch.col.next_trusted_audit.title', 'Next trusted  audit') as string,
        key: 'nextTrustedAudit',
        class: 'data-table-col-fixed data-table-col-middle',
        sortable: true,
        width: 150,
      },
      {
        title: this.$it('planning.card.last-audit.title', 'Last Audit') as string,
        key: 'endedAudits',
        width: 200,
        class: 'data-table-col-fixed',
      },
      {
        title: this.$it('locations.col.total-audits-year.title', 'Total Audits Year') as string,
        key: 'auditsClosedThisYearAmount',
        sortable: true,
        class: 'data-table-col-fixed data-table-col-center',
        tooltip: {
          message: this.$it('locations.col.total-audits-year.title', 'Total Audits Year') as string,
        },
        width: 120,
      },
      {
        title: this.$it('locations.col.audits-with-anomalies.title', 'Audits with Anomalies') as string,
        key: 'auditsClosedWithAnomaliesThisYearAmount',
        sortable: true,
        class: 'data-table-col-fixed data-table-col-center',
        tooltip: {
          message: this.$it('locations.col.audits-with-anomalies.title', 'Audits with Anomalies') as string,
        },
        width: 120,
      },
    ];
  }

  private refetch() {
    (this.$refs.queryDataTable as QueryDataTable).refresh();
    if (this.$refs.query) {
      (this.$refs.query as any).getApolloQuery().refetch();
    }
  }

  private mounted() {
    this.$nextTick(() => {
      this.refetch();
    });
  }

  get filters(): Filter[] {
    return [
      this.accountManagerFilter,
    ];
  }

  private getStarString(rating: AuditRatingString) {
    return Locations.starRating(rating);
  }

  @Meta
  private getMetaInfo() {
    return {
      title: this.$it('global.locations', 'Locations') as string,
    };
  }
}
